import React from "react";
import { Link } from "gatsby";
import { connect } from "react-redux";
import "./Home.scss";
import Button from "@material-ui/core/Button";
import Login from "./login";

class HomeCarousel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loginState: false,
      isSnackbarOpen: false,
      activeID: 2,
      wrapperStyle: {
        backgroundImage: `url('${this.props.content[0].img}')`,
      },
      panelStyle: {
        background: this.props.content[0].colour,
      },
      intervalID: null,
    };
  }

  setCurrentActive(id) {
    this.setState({
      activeID: id,
      wrapperStyle: {
        backgroundImage: `url('${this.props.content[id].img}')`,
      },
      panelStyle: {
        backgroundColor: this.props.content[id].colour,
      },
    });
  }

  componentDidMount() {
    this.setState({
      intervalID: setInterval(() => {
        this.setCurrentActive((this.state.activeID + 1) % 3);
      }, 5000),
    });
  }

  _changeActive(id) {
    this.setCurrentActive(id);
    clearInterval(this.state.intervalID);
    this.setState({
      intervalID: setInterval(() => {
        this.setCurrentActive((this.state.activeID + 1) % 3);
      }, 5000),
    });
  }

  onBtnClick = () => {
    this.setState({ loginState: true });
  };

  render() {
    const { isLoggedIn, content } = this.props;
    const { loginState, activeID, wrapperStyle, panelStyle } = this.state;
    if (isLoggedIn && loginState) {
      this.setState({ loginState: false });
    }
    return (
      <div>
        <section className="wrapper" style={wrapperStyle}>
          <Selectors
            data={content}
            activeID={activeID}
            _changeActive={this._changeActive.bind(this)}
          />
          <Panel
            data={content[activeID]}
            panelStyle={panelStyle}
            linkTo={
              isLoggedIn || content[activeID].freeAccess
                ? content[activeID].link
                : undefined
            }
            onClick={
              isLoggedIn || content[activeID].freeAccess
                ? undefined
                : this.onBtnClick
            }
          />
        </section>
        {loginState ? (
          <Login
            open={true}
            onClose={() => this.setState({ loginState: false })}
          />
        ) : null}
      </div>
    );
  }
}

class Panel extends React.Component {
  render() {
    return (
      <aside className="panel" style={this.props.panelStyle}>
        <h2 className="panel-header">{this.props.data.header}</h2>
        <p className="panel-info">{this.props.data.body}</p>
        {this.props.linkTo !== undefined ? (
          <Link to={this.props.linkTo} style={{ textDecoration: "none" }}>
            <Button className="panel-button">Pergi Sekarang</Button>
          </Link>
        ) : (
          <Button className="panel-button" onClick={this.props.onClick}>
            Pergi Sekarang
          </Button>
        )}
      </aside>
    );
  }
}
class Selectors extends React.Component {
  _handleClick(e) {
    if (this.props.id !== this.props.activeID) {
      this.props._changeActive(this.props.id);
    } else {
      return;
    }
  }
  render() {
    return (
      <div className="selectors">
        {this.props.data.map(item => (
          <Selector
            key={item.id}
            id={item.id}
            _handleClick={this._handleClick}
            _changeActive={this.props._changeActive}
            activeID={this.props.activeID}
          />
        ))}
      </div>
    );
  }
}
class Selector extends React.Component {
  render() {
    let componentClass = "selector";
    if (this.props.activeID === this.props.id) {
      componentClass = "selector active";
    }
    return (
      <div
        className={componentClass}
        onClick={this.props._handleClick.bind(this)}
      ></div>
    );
  }
}

//====================================================redux=======================================================
const mapStateToProps = state => {
  return {
    isLoggedIn: state.account.isLoggedIn,
  };
};

export default connect(
  mapStateToProps,
  null
)(HomeCarousel);
