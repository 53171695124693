import React from "react";
import HomeCarousel from "../components/HomeCarousel";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Image1 from "../images/Pasien.png";
import Image2 from "../images/Admin.png";
import Image3 from "../images/Monitoring.png";

const content = [
  {
    id: 0,
    header: "Antrian Pasien",
    body: "Masuk ke sini untuk mencetak nomor antrian pasien",
    colour: "#0A7093",
    link: "/patient/",
    img: Image1,
  },
  {
    id: 1,
    header: "Kontrol & Konfigurasi",
    body:
      "Petugas loket/counter dapat mengatur flow antrian dan admin dapat melakukan konfigurasi",
    colour: "#B48F7F",
    link: "/settings/",
    img: Image2,
  },
  {
    id: 2,
    header: "Monitor Antrian",
    body: "Berisi informasi panggilan antrian di seluruh loket/counter",
    colour: "#4B947E",
    link: "/info/",
    freeAccess: true,
    img: Image3,
  },
];

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Home" />
    <HomeCarousel content={content} />
    <footer style={{ background: "#568536" }}>
      <p align="center" style={{ padding: 20, color: "#FFFFFF" }}>
        Copyright {new Date().getFullYear()}, © Q-Team
      </p>
    </footer>
  </Layout>
);

export default IndexPage;
